<template>
  <div />
</template>

<script>
  export default {
    name: 'CentumTheme'
  }
</script>

<style lang="scss">
  /* stylelint-disable */

  #cl-form .cl-form {
    color: #000 !important;

    &.cl-form--with-nav {
      padding-top: rem(72px) !important;

      @include mq(medium) {
        padding-top: rem(80px) !important;
      }
    }

    &.cl-form--with-background {
      background-color: #f2f2f2 !important;
    }

    .zg-hero--page-type-funnel-page,
    .zg-hero--page-type-product-page,
    .zg-hero--page-type-content-page {
      background-color: #24385b !important;
    }

    .zg-hero--page-type-funnel-page {
      &.zg-hero--with-background {
        background-color: #f2f2f2 !important;
      }
    }

    .zg-hero--page-type-product-page {
      &.zg-hero--with-background.zg-hero--with-module {
        background-color: #f2f2f2 !important;
      }
    }

    .zg-hero .funnel-page {
      background-color: #f2f2f2 !important;
    }

    .zg-button {
      background-color: #00a7f8 !important;
      border-color: #00a7f8 !important;

      &:hover {
        background-color: #1799c1 !important;
        border-color: #1799c1 !important;
      }

      &--outline {
        background-color: transparent !important;

        &:hover {
          background-color: transparent !important;
        }
      }

      &--link {
        background-color: transparent !important;
        border-color: transparent !important;

        &:hover {
          color: #24385b !important;
        }
      }

      &--ghost {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    .zg-chip {
      border-color: #00a7f8 !important;

      &:hover {
        border-color: #00a7f8 !important;
      }

      &:focus {
        border-color: #00a7f8 !important;
      }

      &:focus:not(:focus-visible) {
        border-color: #00a7f8 !important;
      }

      &--selected {
        border-color: color(secondary-500) !important;

        &:hover {
          background-color: #00a7f8 !important;
          border-color: #00a7f8 !important;
        }
      }
    }

    .zg-radio {
      input {
        background-color: #00a7f8 !important;
        border-color: #00a7f8 !important;
      }
    }

    .zg-loader {
      .circle-animation {
        stroke: #24385b !important;
      }
    }

    .zg-section--background-beige,
    .zg-section--background-white {
      background-color: #f2f2f2 !important;
    }

    .zg-slider {
      /* Chrome */
      input::-webkit-slider-runnable-track {
        background: linear-gradient(to right, #24385b var(--input-percentage), #ccc var(--input-percentage)) !important;
      }

      input::-webkit-slider-thumb {
        background-color: #00a7f8 !important;

        &:hover {
          background-color: #00a7f8 !important;
        }
      }

      /* Firefox */
      input::-moz-range-track {
        background: linear-gradient(to right, #24385b var(--input-percentage), #ccc var(--input-percentage)) !important;
      }

      input::-moz-range-thumb {
        background-color: #00a7f8 !important;

        &:hover {
          background-color: #00a7f8 !important;
        }
      }
    }

    .zg-checkbox {
      span.box {
        background-color: #00a7f8 !important;
        border-color: #00a7f8 !important;
      }
    }

    .add-co-applicant.add-co-applicant--btn {
      background-color: rgba(0, 167, 248, 0.2) !important;
      border-color: #00a7f8 !important;
    }

    .debts {
      .debt {
        background-color: #f2f2f2 !important;
      }

      .add-new-debt {
        span.icon {
          background-color: #00a7f8 !important;
        }
      }
    }

    .zg-info-box {
      .content-block {
        background-color: #fff;
        padding: 16px;
        border-radius: 16px;
      }
    }
  }

  /* stylelint-enable */
</style>
