import { render, staticRenderFns } from "./CentumTheme.vue?vue&type=template&id=44796ab5"
import script from "./CentumTheme.vue?vue&type=script&lang=js"
export * from "./CentumTheme.vue?vue&type=script&lang=js"
import style0 from "./CentumTheme.vue?vue&type=style&index=0&id=44796ab5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports